import { Route, Routes } from "react-router-dom"
import NotFound from "./views/NotFound/NotFound"
import Cuestionario from "./views/Cuestionario/Cuestionario"
import Gracias from "./views/Gracias/Gracias"
import { useEffect } from "react"
import css from './App.module.css'


const App = ()=> {
 
  useEffect(()=> {
    document.documentElement.style.setProperty("--available-height", `${window.innerHeight}px`);
  }, [])

  useEffect(()=> {
    const handleResize = ()=> document.documentElement.style.setProperty("--available-height", `${window.innerHeight}px`);
    window.addEventListener('resize', handleResize)
    return ()=> window.removeEventListener('resize', handleResize)
  }, [])
  
  return (
    <div className={css.app}>
      <Routes>
        <Route path='/cuestionario/:alumnoId' element={<Cuestionario />} />
        <Route path='/gracias' element={<Gracias />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default App