import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import css from './RadioQuestion.module.css'

const RadioQuestion = ({ question, options, value, onChange }) => (
  <div className={css.question}>
    <Typography className={css.title} variant="h5">
      {question}
    </Typography>
    <div className={css.radio}>
      <FormControl>
        <RadioGroup
          value={value}
          name="radio-buttons-group"
        >
          {options.map(option=> (
            <FormControlLabel 
              key={option}
              value={option} 
              control={<Radio />} 
              label={option} 
              onChange={e=> onChange(e.target.value)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  </div>
)

export default RadioQuestion






