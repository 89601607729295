import { Slider, Typography } from '@mui/material'
import css from './SliderQuestion.module.css'


const getSliderColor = (value) => {
  if (value > 8) return 'primary'
  if (value > 5) return 'secondary'
  if (value <= 0) return 'success'
  return 'error'
}

const SliderQuestion = ({ question, value, onChange }) => (
  <div className={css.question}>
    <Typography className={css.title} variant="h5">
      {question}
    </Typography>
    <div className={css.slider}>
      <Slider
        value={value}
        marks
        min={1}
        max={10}
        valueLabelDisplay="auto"
        color={getSliderColor(value)}
        onChange={(e, value)=> onChange(value)}
      />
    </div>
  </div>
)

export default SliderQuestion