
const { REACT_APP_API_URL } = process.env

const getFetchOptions = (method, body) => {
  if (!body) {
    return { 
      method,
      headers: { 'Content-Type': 'application/json' }
    }
  }
  return {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }
}

const processRequest = ({ url, method, body }) => {
  const options = getFetchOptions(method, body)
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(res => res.json())
      .then(res => {
        if (res.resultado === 'ok') resolve(res.datos)
        else reject(res.error)
      })
      .catch(() => reject('Error al procesar la petición'))
  })
}

export const GET = (path, data = {}) => {
  const url = REACT_APP_API_URL + path
  const params = new URLSearchParams(data)
  return processRequest({ url: `${url}?${params}`, method: 'GET' })
}

export const POST = (path, data = {}, body) => {
  const url = REACT_APP_API_URL + path
  const params = new URLSearchParams(data)
  return processRequest({ url: `${url}?${params}`, method: 'POST', body })
}

export const PUT = (path, data = {}, body) => {
  const url = REACT_APP_API_URL + path
  const params = new URLSearchParams(data)
  return processRequest({ url: `${url}?${params}`, method: 'PUT', body })
}

export const DELETE = (path, data = {}, body) => {
  const url = REACT_APP_API_URL + path
  const params = new URLSearchParams(data)
  return processRequest({ url: `${url}?${params}`, method: 'DELETE', body })
}