import { Typography } from '@mui/material'
import css from './FaceQuestion.module.css'

const Face = ({ type, isSelected, onClick })=> (
  <div 
    className={css.face}
    data-selected={isSelected}
    data-type={type}
    onClick={onClick}
  >
    <i className='material-icons'>
      {type === 'very good' && 'sentiment_very_satisfied'}
      {type === 'good' && 'sentiment_satisfied'}
      {type === 'neutral' && 'sentiment_neutral'}
      {type === 'bad' && 'sentiment_dissatisfied'}
      {type === 'very bad' && 'sentiment_very_dissatisfied'}
    </i>
  </div>
)

const FaceQuestion = ({ question, value, onChange }) => {

  return (
    <div className={css.question}>
      <Typography className={css.title} variant="h5">
        {question}
      </Typography>
      <div className={css.faces}>
        <Face
          type='very bad'
          isSelected={value === 'very bad'}
          onClick={()=> onChange('very bad')}
        />
        <Face
          type='bad'
          isSelected={value === 'bad'}
          onClick={()=> onChange('bad')}
        />
        <Face
          type='neutral'
          isSelected={value === 'neutral'}
          onClick={()=> onChange('neutral')}
        />
        <Face
          type='good'
          isSelected={value === 'good'}
          onClick={()=> onChange('good')}
        />
        <Face
          type='very good'
          isSelected={value === 'very good'}
          onClick={()=> onChange('very good')}
        />
      </div>
    </div>
  )
}

export default FaceQuestion