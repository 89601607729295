import SliderQuestion from './SliderQuestion/SliderQuestion'
import FaceQuestion from './FaceQuestion/FaceQuestion'
import StarQuestion from './StarQuestion/StarQuestion'
import TextQuestion from './TextQuestion/TextQuestion'
import RadioQuestion from './RadioQuestion/RadioQuestion'

const Question = ({ question, type, options, value, onChange }) => {
  if (type === 'slider') {
    return <SliderQuestion question={question} value={value} onChange={onChange} />
  }
  if (type === 'face') {
    return <FaceQuestion question={question} value={value} onChange={onChange} />
  }
  if (type === 'star') {
    return <StarQuestion question={question} value={value} onChange={onChange} />
  }
  if (type === 'radio') {
    return <RadioQuestion question={question} options={options} value={value} onChange={onChange} />
  }
  if (type === 'text') {
    return <TextQuestion question={question} value={value} onChange={onChange} />
  }
  return null
}

export default Question