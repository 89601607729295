import mundoestudiante from './mundoestudiante.png'
import css from './CuestionarioNoDisponible.module.css'

const CuestionarioNoDisponible = () => (
  <div className={css.main}>
    <img className={css.image} src={mundoestudiante} alt="Mundoestudiante" />
    <p className={css.info}>
      Ahora mismo no hay cuestionarios disponibles.
    </p>
  </div>
)

export default CuestionarioNoDisponible