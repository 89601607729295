import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { StyledEngineProvider } from '@mui/material/styles'
import { NotificationManager } from './context/NotificationManager/NotificationManager'
import theme from './hoc/theme'
import App from './App'
import './index.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  }
})

const app = (
  <React.StrictMode>
    <>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <NotificationManager>
                <App />
              </NotificationManager>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  </React.StrictMode>
)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(app)