import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GET, POST } from '../../utils/request';
import PageLoading from '../../components/PageLoading/PageLoading';
import Question from '../../components/questions/Question';
import { getDefaultValue } from '../../utils/questions';
import { Button } from '@mui/material';
import CuestionarioError from './CuestionarioError/CuestionarioError';
import CuestionarioNoDisponible from './CuestionarioNoDisponible/CuestionarioNoDisponible';
import Stepper from '../../components/Stepper/Stepper';
import css from './Cuestionario.module.css';

const Cuestionario = ()=> {

  const { alumnoId } = useParams()
  const navigate = useNavigate()

  const [cuestionario, setCuestionario] = useState([])
  const [step, setStep] = useState(0)
  const [isError, setError] = useState(false)

  const { isLoading: isEligibleLoading, data: isEligible=false } = useQuery({
    queryKey: ['eligible', alumnoId],
    queryFn: ()=> GET('/api/eligible', { alumnoId })
      .catch(()=> {
        setError(true)
        return false
      })
  })

  const { isLoading, data: datos={} } = useQuery({
    queryKey: ['questions', alumnoId],
    queryFn: ()=> GET('/api/questions-v2', { alumnoId })
      .catch(()=> {
        setError(true)
        return {}
       })
  })

  const { isFetching: isSubmitting, mutate: submit } = useMutation({
    mutationFn: data=> POST('/api/submit', {}, data),
    onSuccess: ()=> {
      navigate('/gracias')
    },
    onError: ()=> {}
  })

  useEffect(()=> {
    if (isLoading) return
    const preguntas = (datos.preguntas || []).map(pregunta=> ({
      ...pregunta,
      value: getDefaultValue(pregunta)
    }))
    setCuestionario(preguntas)
  }, [isLoading, datos])

  const isCuestionaroCompleted = cuestionario.every(pregunta=> pregunta.value || pregunta.optional)

  const firstUnanswered = cuestionario.findIndex(pregunta=> !pregunta.value && !pregunta.optional)

  const handleValueChange = (index, value)=> {
    setCuestionario(prevState=> {
      const newState = [...prevState]
      newState[index].value = value
      return newState
    })
    const pregunta = cuestionario[index]
    if (['face', 'radio', 'star'].includes(pregunta.type)) {
      setStep(s=> s + 1)
    }
  }

  const handleSubmit = ()=> {
    if (isSubmitting) return
    const datosEnvio = {
      alumnoId,
      alumno: datos.alumno,
      profesores: datos.profesores,
      cuestionario,
    }
    submit(datosEnvio)
  }

  const isUnavailable = !isError && !isEligible
  const isOkay = !isError && isEligible

  return (
    <PageLoading isLoading={isLoading || isEligibleLoading}>
      {isError && <CuestionarioError />}
      {isUnavailable && <CuestionarioNoDisponible />}
      {isOkay && (
        <div className={css.main}>
          <Stepper
            intro={null}
            questions={
              cuestionario.map((pregunta, index)=> (
                <Question
                  key={index}
                  type={pregunta.type}
                  question={pregunta.question}
                  options={pregunta.options}
                  value={pregunta.value}
                  onChange={(value)=> handleValueChange(index, value)}
                />
              ))
            }
            end={(
              <div className={css.submit}>
                {!isCuestionaroCompleted && (
                  <p className={css.message}>
                    🙈 ¡Vaya! Te falta alguna{' '}
                    <span className={css.highlight} onClick={()=> setStep(firstUnanswered)}>
                      pregunta
                    </span>
                    {' '}por contestar.
                  </p>
                )}
                {isCuestionaroCompleted && (
                  <p className={css.message}>
                    ¡Muchas gracias por tu participación! 😊
                  </p>
                )}
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large"
                  disabled={!isCuestionaroCompleted || isSubmitting}
                  onClick={handleSubmit}
                >
                  Enviar respuestas
                </Button>
              </div>
            )}
            step={step}
            onNext={()=> setStep(s=> s + 1)}
            onPrevious={()=> setStep(s=> s - 1)}
          />
        </div>
      )}
    </PageLoading>
  );
}

export default Cuestionario;
