import mundoestudiante from './mundoestudiante.png'
import css from './CuestionarioError.module.css'

const CuestionarioError = () => (
  <div className={css.main}>
    <img className={css.image} src={mundoestudiante} alt="Mundoestudiante" />
    <p className={css.info}>
      Ha ocurrido un error al cargar el cuestionario.
    </p>
  </div>
)

export default CuestionarioError