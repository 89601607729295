import { Fab } from '@mui/material'
import logo from './logo-mundoestudiante.png'
import css from './Stepper.module.css'

const Stepper = ({ intro, questions, end, step, onNext, onPrevious })=> {

  const steps = [intro, ...questions, end].filter(Boolean)

  if (steps.length === 0) return null

  const isFirstStep = step === 0
  const isLastStep = step === steps.length - 1

  return (
    <div className={css.main}>
      <header className={css.header}>
        <div 
          className={css.completed} 
          style={{ width: `${(step / steps.length) * 100}%` }} 
        />
      </header>
      <div className={css.container}>
        <div className={css.corporate}>
          <img className={css.logo} src={logo} alt="Mundoestudiante" />
        </div>
        <div 
          className={css.content}
          style={{ transform: `translateX(-${step * 100}vw)` }}
        >
          {steps.map((child, index)=> (
            <div className={css.step} key={index}>
              {child}
            </div>
          ))}
        </div>
        <footer className={css.footer}>
          <Fab
            className={css.button}
            color='default'
            size='small'
            onClick={onPrevious}
            disabled={isFirstStep}
          >
            <i className='material-icons'>arrow_back</i>
          </Fab>
          <Fab
            className={css.button}
            color='primary'
            onClick={onNext}
            disabled={isLastStep}
          >
            <i className='material-icons'>arrow_forward</i>
          </Fab>
        </footer>
      </div>
    </div>
  )

}

export default Stepper