import mundoestudiante from './mundoestudiante.png'
import css from './NotFound.module.css'

const NotFound = () => {
  return (
    <div className={css.main}>
      <img className={css.image} src={mundoestudiante} alt="Mundoestudiante" />
    </div>
  )
}

export default NotFound