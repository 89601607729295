import { TextField, Typography } from '@mui/material'
import css from './TextQuestion.module.css'



const TextQuestion = ({ question, value, onChange }) => (
  <div className={css.question}>
    <Typography className={css.title} variant="h5">
      {question}
    </Typography>
    <div className={css.text}>
      <TextField 
        label="Tu respuesta" 
        variant="outlined" 
        color="primary"
        multiline
        rows={4}
        fullWidth
        value={value}
        onChange={e=> onChange(e.target.value)}
      /> 
    </div>
  </div>
)

export default TextQuestion