import mundoestudiante from './mundoestudiante.png'
import css from './Gracias.module.css'

const Gracias = () => {
  return (
    <div className={css.main}>
      <img className={css.image} src={mundoestudiante} alt="Mundoestudiante" />
      <h1 className={css.title}>
        Gracias por participar
      </h1>
      <p className={css.info}>
        Tu opinión es muy importante para nosotros.
      </p>
    </div>
  )
}

export default Gracias