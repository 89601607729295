import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import css from './StarQuestion.module.css'

const Star = ({ starValue, hoverValue, onMouseEnter, onMouseLeave, onClick })=> (
  <div 
    className={css.star} 
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
  >
    <i className='material-icons'>
      {(hoverValue >= starValue) ? 'star' : 'star_border'}
    </i>
  </div>
)


const StarQuestion = ({ question, value, onChange }) => {

  const [hoverValue, setHoverValue] = useState(value)

  useEffect(()=> {
    setHoverValue(value)
  }, [value])

  const handleStarClick = (value) => {
    onChange(value)
  }
  
  return (
    <div className={css.question}>
      <Typography className={css.title} variant="h5">
        {question}
      </Typography>
      <div className={css.stars}>
        <Star
          starValue={1}
          hoverValue={hoverValue}
          onMouseEnter={()=> setHoverValue(1)}
          onMouseLeave={()=> setHoverValue(value)}
          onClick={()=> handleStarClick(1)}
        />
        <Star
          starValue={2}
          hoverValue={hoverValue}
          onMouseEnter={()=> setHoverValue(2)}
          onMouseLeave={()=> setHoverValue(value)}
          onClick={()=> handleStarClick(2)}
        />
        <Star
          starValue={3}
          hoverValue={hoverValue}
          onMouseEnter={()=> setHoverValue(3)}
          onMouseLeave={()=> setHoverValue(value)}
          onClick={()=> handleStarClick(3)}
        />
        <Star
          starValue={4}
          hoverValue={hoverValue}
          onMouseEnter={()=> setHoverValue(4)}
          onMouseLeave={()=> setHoverValue(value)}
          onClick={()=> handleStarClick(4)}
        />
        <Star
          starValue={5}
          hoverValue={hoverValue}
          onMouseEnter={()=> setHoverValue(5)}
          onMouseLeave={()=> setHoverValue(value)}
          onClick={()=> handleStarClick(5)}
        />
      </div>
    </div>
  )
}

export default StarQuestion